:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --navbar-bg: #f8f9fa;
  --modal-bg: #ffffff;
  --home-bg-gradient: linear-gradient(to left, #e0e0e0, #f5f5f5);
  --sticky-shadow: 0px 10px 10px 0px rgba(200, 200, 200, 0.3);
  --sticky-bg: rgba(255, 255, 255, 0.85);
  --sticky-blur: blur(10px);
  --footer-bg: rgb(255, 255, 255);
  --inputbar-bg: #f5f5f5;
  --inbutbar-border: #cccccc;  
  --inputbar-glow: #fff;
  --inputbar-placeholder: #4d4d4d;
  --filter-icon-gradient: linear-gradient(180deg, #b9b9c0, #d8d8d8, #b5b5ca);
  --filter-icon-border: #504e5a;
  --filter-icon-stroke: #000;
  --file-item-bg: rgba(212, 212, 212, 0.7);
  --accordion-header-bg: rgba(white);
  --accordion-body-bg: rgba(217, 217, 217, 0.7);
  --langswitch-bg: rgba(212, 212, 212, 0.7);
  --file-item-border: 2px solid rgba(0, 0, 0, 0.1);
  --login-form-input: rgb(201, 201, 201);
  --time-line-item: rgba(0, 0, 0, 0.75);
  --tech-bar-bg: #b9b9b9;
  --language-tag-bg: #e0e0e0;

  --fullscreen-modal-bg: rgba(255, 255, 255, 0.8) ;

  --scrollbar-track: #a9a9a9;
}

/* Variablen für Dark Mode */
body.dark-mode {
  --background-color: #121212;
  --text-color: #e0e0e0;
  --navbar-bg: #333;
  --modal-bg: #333;
  --home-bg-gradient: linear-gradient(to bottom left,rgba(17, 16, 16, 0.582),rgba(12, 8, 24, 0.904));
  --sticky-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
  --sticky-bg: #1b1a2ea9;
  --sticky-blur: blur(15px);
  --footer-bg: rgb(10, 4, 22);
  --inputbar-bg: #010201;
  --inbutbar-border: #1c191c;
  --inputbar-glow: #000;
  --inputbar-placeholder: #c0b9c0;
  --filter-icon-gradient: linear-gradient(180deg, #161329, black, #1d1b4b);
  --filter-icon-border: #3d3a4f; 
  --filter-icon-stroke: #d6d6e6;
  --file-item-bg: rgba(0, 0, 0, 0.7);
  --file-item-border: 2px solid rgba(0, 0, 0, 0.1);
  --tech-bar-bg: #e0e0e0;
  --langswitch-bg: rgba(38, 38, 38, 0.7);
  --accordion-header-bg: rgba(0, 0, 0, 0.7);
  --accordion-body-bg: rgba(38, 38, 38, 0.7);
  --login-form-input: rgba(255, 255, 255, 0.1);
  --time-line-item: rgba(255, 255, 255, 0.75);
  --language-tag-bg: #303030;
  
  --fullscreen-modal-bg: #0000007e;

  --scrollbar-track: #2d1950;
}


html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(12, 8, 24, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  /* --imp-text-color: rgb(0, 0, 0);  */

  --imp-text-color: #c770f0;
}

html, body {
  scroll-behavior: auto !important;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: var(--home-bg-gradient), var(--image-gradient);
}


.purple {
  color: var(--imp-text-color) !important;
}

button:focus {
  box-shadow: none !important;
}

/* --------- */
/*  Preloader */
/* --------- */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}

/* --------- */
/*Scrollbar   */
/* --------- */

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--scrollbar-track);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(178, 121, 216, 0.959);
  border-radius: 12px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(222, 130, 235, 0.911);
  border-radius: 12px;
}

/* --------- */
/* Navbar Section  */
/* --------- */
.sticky {
  background-color: var(--sticky-bg) !important;
  transition: all 0.3s ease-out 0s !important;
  box-shadow: var(--sticky-shadow) !important;
  backdrop-filter: var(--sticky-blur) !important;
}

.navbar {
  position: fixed !important;
  transition: all 0.3s ease-out 0s !important;
  padding: 0.3rem 2rem !important;
  font-size: 1.2rem !important;
}

.navbar-toggler {
  position: relative !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.navbar-toggler span {
  display: block !important;
  background-color: #be50f4 !important;
  height: 4px !important;
  width: 27px !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
  transform: rotate(0deg) !important;
  left: 0 !important;
  opacity: 1 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 !important;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out !important;
  transition: transform 0.35s ease-in-out !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(135deg) !important;
  opacity: 0.9 !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px !important;
  visibility: hidden !important;
  background-color: transparent !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute !important;
  left: 12px !important;
  top: 10px !important;
  transform: rotate(-135deg) !important;
  opacity: 0.9 !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem !important;
    font-size: 1.4rem !important;
    background-color: var(--sticky-bg) !important;
    backdrop-filter: var(--sticky-blur);
    box-shadow: var(--sticky-shadow)
  }
  .navbar-nav .nav-item a::after {
    display: none !important;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) !important;
}

.logo {
  height: 2em !important;
  width: 3.1em !important;
}

.navbar-nav .nav-link {
  color: var(--text-color) !important;
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.nav-link {
  padding: 0.8rem 1rem !important;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem !important;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #c95bf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

/* --------- */
/* Home section */
/* --------- */
.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.1s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}

.home-section {
  position: relative;
  z-index: -1;
  background-position: top center;
  background-repeat: no-repeat;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.home-content {
  padding: 9rem 0 2rem !important;
  color: var(--text-color);
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}

.heading-name {
  font-size: 2.5em !important;
  padding-left: 45px !important;
}

.main-name {
  color: #cd5ff8;
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
  }
  .Typewriter__cursor {
    font-size: 1.6em!important;
  }
}

.myAvtar {
  justify-content: center !important;
  padding-top: 9em !important;

}

.myAvatar-img {
  width: 250px;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 200px;
  z-index: 200;
  position: absolute;
  bottom: 0;
  transition: opacity 0.3s ease;
}

.myAvatar-container:hover .myAvatar-img {
  opacity: 0;
}

.myAvatar-img-hover {
  width: 250px;
  user-select: none;
  -webkit-user-drag: none;
  border-radius: 200px;
  z-index: 200;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.myAvatar-container:hover .myAvatar-img-hover {
  opacity: 1;
}

.myAvatar-circle {
  width: 250px;
  height: 250px;
  border-radius: 200px;
  z-index: -1;
  background-color: #8a4ca8;
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}




@media (max-width: 767px) {
  .myAvtar {
    padding-top: 2em !important;
    padding-bottom: 2em !important;
  }
}

.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: var(--text-color) !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: var(--text-color) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #801f95;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #87209e;
}

.social-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #700c86 !important;
}

/* --------- */
/* Footer */
/* --------- */
.socialicons {
  color: var(--text-color);
}

.footer {
  background-color: var(--footer-bg);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}
.footer-copywright {
  text-align: center !important;
}

.footer-body {
  z-index: 1;
  text-align: center !important;
}

@media (max-width: 1087px) {
  .impressumbutton {
    margin-top: 10px;
  }
}


@media (max-width: 767px) {
  .footer-copywright {
    text-align: center !important;
  }

  .footer-body {
    text-align: center !important;
  }
}

.footer h3 {
  font-size: 1em;
  color: var(--text-color) !important;
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
}
.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}

.blockquote-footer {
  color: #a588c0 !important;
}
/* --------- */
/* Projects */
/* --------- */
.project-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  min-height: 100vh;
} 

.language-tags {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.language-tag {
  background-color: var(--language-tag-bg); /* Farbe des Tags */
  border-radius: 5px;
  padding: 5px 10px;
  margin: 2px;
  font-size: 14px;
}

.cardtxt {
  text-align: center;
}


.cardimg {
  display: flex; 
  justify-content: center;
  align-items: center;
}

.project-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.project-card-view {
  box-shadow: 0 4px 5px 3px rgba(119, 53, 136, 0.459) !important;
  color: var(--text-color) !important;
  background-color: transparent !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}



.project-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 4px 4px 5px rgba(129, 72, 144, 0.561) !important;
}

.blog-card {
  padding-top: 50px !important;
  padding-bottom: 50px !important;
  padding-left: 25px !important;
  padding-right: 25px !important;
  height: auto !important;
}

.blog-card-view {
  background-color: transparent !important;
  box-shadow: 0 3px 3px 2px rgba(145, 77, 161, 0.459) !important;
  color: var(--text-color) !important;
  transition: all 0.5s ease 0s !important;
  height: 100% !important;
}

.blog-link {
  color: var(--text-color) !important;
  text-decoration: none !important;
}

.blog-link:hover {
  cursor: pointer !important;
}

.blog-card-view:hover {
  transform: scale(1.02) !important;
  overflow: hidden !important;
  box-shadow: 0 3px 3px 5px rgba(155, 88, 173, 0.65) !important;
}

.card-img-top {
  padding: 20px !important;
  opacity: 0.9 !important;
  border-radius: 10px !important;
}

.blog-img {
  padding: 0px !important;
  opacity: 0.8 !important;
  border-radius: 0px !important;
}

.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.project-heading {
  color: var(--text-color) !important;
  font-size: 2.3em !important;
  font-weight: 500 !important;
  padding-top: 10px !important;
}

/* --------- */
/* About */
/* --------- */



.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;

  color: var(--text-color) !important;
}

.tech-col {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.tech-item {
  width: 100%;
  max-width: 500px;
  text-align: center;
  opacity: 0;

  transition: opacity 0.6s ease-out, transform 0.6s ease-out;

}

.tech-item.visible {
  opacity: 1;

}

.tech-icon {
  font-size: 2rem;
}

.tech-name {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

.tech-bar {
  width: 100%;
  background-color: var(--tech-bar-bg);
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
}

.tech-bar-fill {
  height: 20px;
  background-color: #4caf50;
  border-radius: 5px 0 0 5px;
  transition: width 2s ease;
}



.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: var(--text-color) !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}

/* --------- */
/* Login */
/* --------- */

.login-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-form {
  position: relative; 
  background: var(--file-item-bg); 
  border-radius: 10px;
  border: var(--file-item-border);
  padding: 50px 35px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  z-index: 10; 
}
.login-form *{
  font-family: 'Poppins',sans-serif;
  letter-spacing: 0.5px;
  outline: none;
  border: none;
}


.particle-container {
  z-index: -1; 
}


.login-form h1 {
  color: var(--imp-text-color);
  text-align: center;
  margin-bottom: 20px;
}

.login-form label {
  color: var(--text-color);
  display: block;
  margin-bottom: 14px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500;
}

.login-form input {
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 5px;
  background: var(--login-form-input);
  color: var(--text-color);
  font-size: 15px;
  font-weight: 300;
}

.login-form button {
  margin-top: 30px;
  width: 100%;
  padding: 15px 0;
  border: none;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  background: var(--imp-text-color);
  color: #fff;
  cursor: pointer;
  transition: all 0.5s;
}

.login-form button:hover {
  background: #a05dc8;
}

.login-form p {
  color: #f88;
  text-align: center;
  margin-top: 10px;
}

/* --------- */
/* Documents */
/* --------- */

.sr-only {
  color: var(--imp-text-color);
  margin-top: 30px;
}


.docs-container {
  position: relative;

}

.check {
  cursor: pointer;
  position: relative;
  margin: auto;
  width: 18px;
  height: 18px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.check:before {
  content: "";
  position: absolute;
  top: -15px;
  left: -15px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(34, 50, 84, 0.03);
  opacity: 0;
  transition: opacity 0.2s ease;
}

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke: #c8ccd4;
  stroke-width: 1.5;
  transform: translate3d(0, 0, 0);
  transition: all 0.2s ease;
}

.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}

.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}

.check:hover:before {
  opacity: 1;
}

.check:hover svg {
  stroke: lime;
}

#cbx:checked + .check svg {
  stroke: lime;
}

#cbx:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}

#cbx:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}


.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  color: var(--text-color) !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #8a49a8;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #5234795d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}


.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em !important;
  padding-top: 10px !important;
}

.fork-btn-inner {
  line-height: 1.4em !important;
  background-color: #934cce5e !important;
  padding: 0.25rem 1.1rem !important;
  vertical-align: middle !important;
  text-align: center !important;
}

.fork-btn-inner:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}
.fork-btn-inner::after {
  display: none !important;
}

/* --------- */
/* Register */
/* --------- */

/* Stile für den Register-Container */
.register-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--section-background-color);
}

/* Stile für das Registrierungsformular */
.register-form {
  background: rgba(0, 0, 0, 0.7); /* Durchsichtiger Hintergrund */
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

/* Stile für Erfolgsmeldungen */
.success-message {
  color: #4caf50; /* Grün für erfolgreiche Nachrichten */
  margin-top: 10px;
}

/* Stile für Fehlermeldungen */
.error-message {
  color: #f44336; /* Rot für Fehlermeldungen */
  margin-top: 10px;
}

/* --------- */
/* Documents extra */
/* --------- */

.white,
.border,
.darkBorderBg,
.glow {
  max-height: 70px;
  max-width: 314px;
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
  z-index: -1;
  /* Border Radius */
  border-radius: 12px;
  filter: blur(3px);
  border: none!important;
}
.inputbar {
  background-color: var(--inputbar-bg);
  border: none;
  /* padding:7px; */
  width: 301px;
  height: 56px;
  border-radius: 10px;
  color: var(--text-color);
  padding-inline: 59px;
  font-size: 18px;
  border: none;
}
#poda {
  display: flex;
  align-items: center;
  justify-content: center;
}
.inputbar::placeholder {
  color: var(--inputbar-placeholder);
}

.inputbar:focus {
  outline: none;
}

#main:focus-within > #inputbar-mask {
  display: none;
}

#inputbar-mask {
  pointer-events: none;
  width: 100px;
  height: 20px;
  position: absolute;
  background: linear-gradient(90deg, transparent, black);
  top: 18px;
  left: 70px;
}
#pink-mask {
  pointer-events: none;
  width: 30px;
  height: 20px;
  position: absolute;
  background: #cf30aa;
  top: 10px;
  left: 5px;
  filter: blur(20px);
  opacity: 0.8;
  transition: all 2s;
}
#main:hover > #pink-mask {
  opacity: 0;
}

.white {
  max-height: 63px;
  max-width: 307px;
  border-radius: 10px;
  filter: blur(2px);
}

.white::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(83deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  filter: brightness(1.4);
  background-image: conic-gradient(
    rgba(0, 0, 0, 0) 0%,
    #a099d8,
    rgba(0, 0, 0, 0) 8%,
    rgba(0, 0, 0, 0) 50%,
    #dfa2da,
    rgba(0, 0, 0, 0) 58%
  );
  transition: all 2s;
}
.border {
  max-height: 59px;
  max-width: 303px;
  border-radius: 11px;
  filter: blur(0.5px);
}
.border::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(70deg);
  position: absolute;
  width: 600px;
  height: 600px;
  filter: brightness(1.3);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    var(--inbutbar-border),
    #402fb5 5%,
    var(--inbutbar-border) 14%,
    var(--inbutbar-border) 50%,
    #cf30aa 60%,
    var(--inbutbar-border) 64%
  );
  transition: all 2s;
}
.darkBorderBg {
  max-height: 65px;
  max-width: 312px;
}
.darkBorderBg::before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(82deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    #18116a,
    rgba(0, 0, 0, 0) 10%,
    rgba(0, 0, 0, 0) 50%,
    #6e1b60,
    rgba(0, 0, 0, 0) 60%
  );
  transition: all 2s;
}
#poda:hover > .darkBorderBg::before {
  transform: translate(-50%, -50%) rotate(262deg);
}
#poda:hover > .glow::before {
  transform: translate(-50%, -50%) rotate(240deg);
}
#poda:hover > .white::before {
  transform: translate(-50%, -50%) rotate(263deg);
}
#poda:hover > .border::before {
  transform: translate(-50%, -50%) rotate(250deg);
}

#poda:hover > .darkBorderBg::before {
  transform: translate(-50%, -50%) rotate(-98deg);
}
#poda:hover > .glow::before {
  transform: translate(-50%, -50%) rotate(-120deg);
}
#poda:hover > .white::before {
  transform: translate(-50%, -50%) rotate(-97deg);
}
#poda:hover > .border::before {
  transform: translate(-50%, -50%) rotate(-110deg);
}

#poda:focus-within > .darkBorderBg::before {
  transform: translate(-50%, -50%) rotate(442deg);
  transition: all 4s;
}
#poda:focus-within > .glow::before {
  transform: translate(-50%, -50%) rotate(420deg);
  transition: all 4s;
}
#poda:focus-within > .white::before {
  transform: translate(-50%, -50%) rotate(443deg);
  transition: all 4s;
}
#poda:focus-within > .border::before {
  transform: translate(-50%, -50%) rotate(430deg);
  transition: all 4s;
}

.glow {
  overflow: hidden;
  filter: blur(30px);
  opacity: 0.4;
  max-height: 130px;
  max-width: 354px;
}
.glow:before {
  content: "";
  z-index: -2;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(60deg);
  position: absolute;
  width: 999px;
  height: 999px;
  background-repeat: no-repeat;
  background-position: 0 0;
  /*border color, change middle color*/
  background-image: conic-gradient(
    var(--inputbar-glow),
    #402fb5 5%,
    var(--inputbar-glow) 38%,
    var(--inputbar-glow) 50%,
    #cf30aa 60%,
    var(--inputbar-glow) 87%
  );
  /* change speed here */
  transition: all 2s;
}

@keyframes rotate {
  100% {
    transform: translate(-50%, -50%) rotate(450deg);
  }
}
@keyframes leftright {
  0% {
    transform: translate(0px, 0px);
    opacity: 1;
  }

  49% {
    transform: translate(250px, 0px);
    opacity: 0;
  }
  80% {
    transform: translate(-40px, 0px);
    opacity: 0;
  }

  100% {
    transform: translate(0px, 0px);
    opacity: 1;
  }
}

#filter-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  max-height: 40px;
  max-width: 38px;
  height: 100%;
  width: 100%;

  isolation: isolate;
  overflow: hidden;
  /* Border Radius */
  border-radius: 10px;
  background: var(--filter-icon-gradient);
  border: 1px solid transparent;
}

.filtericoncolor {
  stroke: var(--filter-icon-stroke);
}

.filterBorder {
  height: 42px;
  width: 40px;
  position: absolute;
  overflow: hidden;
  top: 7px;
  right: 7px;
  border-radius: 10px;
}

.filterBorder::before {
  content: "";

  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(90deg);
  position: absolute;
  width: 600px;
  height: 600px;
  background-repeat: no-repeat;
  background-position: 0 0;
  filter: brightness(1.35);
  background-image: conic-gradient(
    rgba(0, 0, 0, 0),
    var(--filter-icon-border),
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 50%,
    var(--filter-icon-border),
    rgba(0, 0, 0, 0) 100%
  );
  animation: rotate 4s linear infinite;
}
#main {
  position: relative;
}
#search-icon {
  position: absolute;
  left: 20px;
  top: 15px;
}

.file-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  border: var(--file-item-border);
  border-radius: 5px;
  background: var(--file-item-bg); 
  transition: all 0.5s;
}

.filecard {
  color: var(--text-color);
  background: var(--file-item-bg); 
  border: var(--file-item-border);
  border-radius: 10px;
}

.file-name {
  flex: 1;
  margin-left: 50px;
  transition: all 0.5s;
  color: var(--text-color);
}

.downloadbutton {
  margin: 5px;
}

@media (max-width: 660px) {
  .previewbutton {
    display: none !important;
  }
}

@media (max-width: 500px) {
  .previewbutton {
    display: none !important;
  }
  .downloadbutton {
    display: none !important;
  }
  .file-name {
    margin-left: 0px;
    font-size: smaller;
  }
  .file-checkbox {
    display: none;
  }
}

/* --------- */
/* About extra */
/* --------- */

.item1 {
  position: absolute;
  top: 0; /* Start unterhalb des Containers */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./Assets/career/1.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.5s all;
  border-radius: 6px;
}

.timeline-content:hover .item1 {
  opacity: 1;
}

.item2 {
  position: absolute;
  top: 0; /* Start unterhalb des Containers */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./Assets/career/2.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.5s all;
  border-radius: 6px;
}

.timeline-content:hover .item2 {
  opacity: 1;
}

.item3 {
  position: absolute;
  top: 0; /* Start unterhalb des Containers */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./Assets/career/3.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.5s all;
  border-radius: 6px;
}

.timeline-content:hover .item3 {
  opacity: 1;
}

.item4 {
  position: absolute;
  top: 0; /* Start unterhalb des Containers */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./Assets/career/4.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.5s all;
  border-radius: 6px;
}

.timeline-content:hover .item4 {
  opacity: 1;
}

.item5 {
  position: absolute;
  top: 0; /* Start unterhalb des Containers */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('./Assets/career/5.jpg');
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: 0.5s all;
  border-radius: 6px;
}

.timeline-content:hover .item5 {
  opacity: 1;
}


/* Die Timeline-Klasse, die speziell für die Timeline-Komponente verwendet wird */
.timeline-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

/* Die vertikale Linie der Timeline */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: var(--text-color);
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
  z-index: -1; /* Setze z-index der Linie auf 0, um sicherzustellen, dass die Punkte darüber liegen */
}

/* Container um den Inhalt der Timeline */
.timeline-item {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%;
  opacity: 0; /* Start mit unsichtbar */
  transform: translateY(50px); /* Start außerhalb des Sichtbereichs */
  transition: opacity 0.6s ease-out, transform 0.6s ease-out; /* Animationen */
  z-index: 1;
}

/* Die Kreise auf der Timeline */
.timeline-item::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -17px;
  background-color: var(--time-line-item);
  border: 4px solid var(--imp-text-color) !important;
  top: 15px;
  border-radius: 50%;
  z-index: 1!important; /* Setze z-index der Punkte auf 1, damit sie über der Linie liegen */
}

/* Container auf der linken Seite */
.timeline-item.left {
  left: -0.4%;
}

/* Container auf der rechten Seite */
.timeline-item.right {
  left: 50.29%;
}

/* Pfeile für Container auf der linken Seite */
.timeline-item.left::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  right: 30px;
  border: medium solid var(--text-color);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent var(--text-color);
}

/* Pfeile für Container auf der rechten Seite */
.timeline-item.right::before {
  content: " ";
  height: 0;
  position: absolute;
  top: 22px;
  width: 0;
  z-index: 1;
  left: 31px;
  border: medium solid var(--text-color);
  border-width: 10px 10px 10px 0;
  border-color: transparent var(--text-color) transparent transparent;
}

/* Korrektur des Kreises für Container auf der rechten Seite */
.timeline-item.right::after {
  left: -16px;
}

/* Der eigentliche Inhalt der Timeline */
.timeline-content {
  padding: 20px 30px;
  background: var(--file-item-bg);
  border: var(--file-item-border);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  position: relative;
  border-radius: 6px;
}

/* Sichtbarkeitsklassen */
.timeline-item.visible {
  opacity: 1;
  transform: translateY(0);
}


/* Medienabfragen für responsive Designs bei Bildschirmen kleiner als 600px */
@media screen and (max-width: 600px) {
  .timeline-item.left::before {
    transform: rotateY(180deg);
  }
  
  /* Pfeile für Container auf der rechten Seite */
  .timeline-item.right::before {
    left: 61px;
  }

  /* Platzierung der vertikalen Linie */
  .timeline-container::after {
    left: 31px;
  }

  /* Vollbreite Container */
  .timeline-item {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Pfeile für Container auf der linken Seite */
  .timeline-item::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Korrektur der Kreise */
  .timeline-item.left::after{
    left: 21px;
  }

  .timeline-item.right::after {
    left: 18px;
  }

  /* Alle Container rechts verhalten sich wie die Container auf der linken Seite */
  .timeline-item.right {
    left: 0%;
  }
}

@media screen and (max-width: 400px) {


  .timeline-item.left::after{
    left: 20px;
  }


}
.pdf-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.nav-left {
  display: flex;
  align-items: center;
  flex: 1;
}

.nav-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.nav-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.nav-right .btn {
  margin-left: 5px;
}

.pdf-preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pdf-thumbnails {
  width: 120px;
  overflow-y: auto;
  background-color: var(--background-color);
  padding: 10px;
}

.thumbnail-item {
  margin-bottom: 10px;
  cursor: pointer;
  text-align: center;
}

.thumbnail-document {
  user-select: none;
}

.page-number {
  font-size: 12px;
  margin-top: 5px;
  z-index: 1000;
  color: yellow;
}

.pdf-preview {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.pdf-container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-page-wrapper {
  width: 100%;
  overflow: hidden;
}

.tpdf-page-wrapper {
  margin-bottom: 100px;
  width: 100%;
}

.pdf-page {
  width: 100%;
  user-select: none;
}

.fullscreen-dialog .modal-content {
  height: 100%;
  display: flex;
  background-color: var(--fullscreen-modal-bg);
  backdrop-filter: var(--sticky-blur);
}

.fullscreen-dialog .pdf-preview {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.fullscreen-dialog .pdf-container {
  flex-grow: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Modal {
  background-color: var(--background-color);
}

.modal-content{
  display: flex;
  justify-content: space-between; /* Platz zwischen den Elementen */
  padding: 10px; /* Abstand innerhalb des Modals */
  border-radius: 15px; /* Runde Ecken */
  background-color: var(--background-color)!important;
}

.settings-modal .modal-content{
  display: flex;
  flex-direction: row; /* Elemente horizontal anordnen */
  justify-content: space-between; /* Platz zwischen den Elementen */
  align-items: center; /* Elemente vertikal zentrieren */
  padding: 10px; /* Abstand innerhalb des Modals */
  border-radius: 15px; /* Runde Ecken */
  background-color: var(--background-color)!important;
}

.modal-content{
  border: none;
}

.settings-modal .theme-toggle {
  display: flex;
  align-items: center;
}

.settings-modal .btn-outline-secondary {
  padding: 0.5rem;
  margin: 10px;
}

.settings-modal .modal-footer {
  display: flex;
  justify-content: center;
}

.dropdownitem {
  background-color: var(--sticky-bg);
  border: none;
}

.b2 {
  color: var(--text-color);
}

/* --------- */
/* Dropdown */
/* --------- */


.custom-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  background-color: var(--inputbar-glow);
  color: var(--text-color);
  border: 2px solid #000000;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  width: 150px; /* Kann nach Bedarf angepasst werden */
  text-align: left;
  transition: background-color 0.3s ease;
}

.dropdown-toggle-open {
  border-radius: 6px 6px 0px 0px;
}

.dropdown-toggle:hover {
  background-color: var(--inbutbar-border); /* Farbe beim Hover */
}

.dropdown-menu-custom {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: var(--langswitch-bg);
  backdrop-filter: var(--sticky-blur);
  border-top: none; /* Entferne den oberen Rand */
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 100%; /* Gleiche Breite wie der Button */
  list-style: none;
  padding: 0;
  margin: 0;
  color: var(--text-color);
  border-radius: 0px 0px 6px 6px; /* Runde Ecken unten */
}

.dropdown-item-custom {
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-menu-custom li:last-child {
  border-radius: 0px 0px 6px 6px;

}

.dropdown-item-custom:hover {
  background-color: var(--inputbar-glow);
}


.custom-accordion {
  width: 100%;
  margin: auto;
}

.accordion-section {
  border: var(--file-item-border);
  border-radius: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.accordion-header {
  padding: 15px;
  background-color: var(--accordion-header-bg);
  cursor: pointer;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-toggle-icon {
  transition: transform 0.3s ease;
  color: var(--text-color);
}

.accordion-toggle-icon.open {
  transform: rotate(180deg);
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  background-color: var( --accordion-body-bg);
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.accordion-body.open {
  max-height: 1000px;
  background-color: var( --accordion-body-bg);
  padding: 15px; 
}

.accordion-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s ease-in-out, padding 0.5s ease-in-out;
}

.accordion-body.open {
  max-height: 1000px;
}

.average-text {
  height: 100px;
  text-justify: center;
  margin-top: "10px"; 
  text-align: "center"; 
}

.average-text .Typewriter__wrapper {
  position: relative!important;
  font-size: 2.2em !important;
  color: #be6adf !important;
  font-weight: 600 !important;
}

.average-text .Typewriter__cursor {
  font-size: 2.4em !important;
  color: #b562d6 !important;
  display:inline!important;
}

input[type=range]:focus {
  outline: none;
}

input[type=range] {
	appearance: none;
	width: 100%;
	border-radius: 6px;
	height: 12px;
	border: 1px solid var(--inbutbar-border);
	background-color: var(--tech-bar-bg); 
  color: #000;
}

input[type=range]::-webkit-slider-thumb {
	appearance: none;
	width: 20px;
	height: 20px; 
	border-radius: 50%; 
	background-color: var(--imp-text-color); 
	cursor: pointer;
	border: 2px solid #00000024; 
}


input[type=range]::-moz-range-thumb {
	width: 20px;
	height: 20px; 
	border-radius: 50%;
	background-color: var(--imp-text-color); 
	cursor: pointer;
	border: 2px solid #00000024; 
}

input[type=range]::-ms-thumb {
	width: 20px; 
	height: 20px;
	border-radius: 50%; 
	background-color: var(--imp-text-color); 
	cursor: pointer;
	border: 2px solid #00000024; 
}